import React from "react";
import ReactDOM from "react-dom";

import App from "./main";
import * as serviceWorker from "./serviceWorker";

import { StyledEngineProvider } from "@mui/material/styles";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
