import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { Component } from "react";
import momentTz from "moment-timezone";

import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import CustomNavBar from "../components/navbar";
import { callGetData } from "../services/api";

class ViewDataScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      items: [],
      itemsBackup: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      criteria1a: false,
      criteria1b: false,
      per1a: 0,
      type1a: ">",
      value1b: 0,
      type1b: ">",
      filteredStocks: [],
      keyC1a: Math.random(),
      keyC1b: Math.random(),
      rows: [],
      cols: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    let d = await callGetData();
    if (d.status) {
      this.setState({
        items: d.result,
        itemsBackup: d.result,
        rows: d.rows,
        cols: d.cols,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickFilter() {
    let items = this.state.itemsBackup;

    let fItems = items.filter((d) => {
      return moment(d.stockDate).isBetween(
        moment(this.state.startDate),
        moment(this.state.endDate)
      );
    });
    let stockData = {};
    fItems.forEach((e) => {
      if (!stockData[e.stockSymbol]) {
        stockData[e.stockSymbol] = [];
      }
      stockData[e.stockSymbol].push(e);
    });
    let fData = [];

    for (const key in stockData) {
      if (Object.hasOwnProperty.call(stockData, key)) {
        var element = stockData[key];
        element.sort((a, b) => {
          return new Date(a.stockDate) - new Date(b.stockDate);
        });
        let check = false;
        if (element.length > 0) {
          let startResult = element[0];
          let endResult = element[element.length - 1];
          let check1a = true;
          if (this.state.criteria1a) {
            let change = (startResult.eps - endResult.eps) / endResult.eps;
            let type1a = this.state.type1a;
            if (type1a === ">") {
              check1a = change > this.state.per1a;
            }
            if (type1a === "<") {
              check1a = change < this.state.per1a;
            }
            if (type1a === "=") {
              check1a = change === this.state.per1a;
            }
          }
          let check1b = true;
          if (this.state.criteria1b) {
            let change = startResult.eps - endResult.eps;
            let type1b = this.state.type1b;
            if (type1b === ">") {
              check1b = change > this.state.value1b;
            }
            if (type1b === "<") {
              check1b = change < this.state.value1b;
            }
            if (type1b === "=") {
              check1b = change === this.state.value1b;
            }
          }
          check = check1a && check1b;
        }

        if (check) {
          fData.push(key);
        }
      }
    }

    this.setState({ filteredStocks: fData });
  }

  onClickReset() {
    this.setState({
      filteredStocks: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      criteria1a: false,
      criteria1b: false,
      per1a: 0,
      type1a: ">",
      value1b: 0,
      type1b: ">",
      keyC1a: Math.random(),
      keyC1b: Math.random(),
    });
  }

  renderData() {
    let returnVals = [];
    let items = this.state.items;
    items.sort((a, b) => new Date(b.stockDate) - new Date(a.stockDate));
    items.forEach((element) => {
      if (element.stockSymbol) {
        returnVals.push(
          <tr key={Math.random()}>
            <td>{element.stockSymbol || ""}</td>
            <td>{element.div || "0"}</td>
            <td>{element.price || ""}</td>
            <td>{element.volume || ""}</td>
            <td>{element.eps || ""}</td>
            <td>
              {momentTz(element.stockDate)
                .tz("america/panama")
                .startOf("day")
                .format("Do MMM YYYY")}
            </td>
            <td>{element.divYId.toFixed(2) || ""}</td>
            <td>{element.earnYId.toFixed(2) || ""}</td>
            <td>{element.perRatio.toFixed(2) || ""}</td>
          </tr>
        );
      }
    });
    return returnVals;
  }

  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />

          <div>
            <Card>
              <Card.Header style={{ fontWeight: "bold" }}>
                Filter V1
              </Card.Header>
            </Card>
            <div style={{ height: 30 }} />
            <Card>
              <div className="container-fluid">
                <div style={{ height: 10 }} />
                <Form>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          placeholder="Start Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={this.state.endDate}
                          placeholder="End Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <>
                    <Row>
                      <Col xs={6} md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox">
                          <Form.Check
                            key={this.state.keyC1a}
                            type="checkbox"
                            label="Critera 1A : Percentage change in EPS"
                            value={this.state.criteria1a}
                            name="criteria1a"
                            onChange={this.handleInputChange.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {this.state.criteria1a && (
                      <Row>
                        <Col xs={6} md={1}>
                          <Form.Group>
                            <Form.Label>Condition</Form.Label>
                            <Form.Control
                              as="select"
                              name="type1a"
                              value={this.state.type1a}
                              onChange={this.handleInputChange.bind(this)}>
                              <option value={">"}>{">"}</option>
                              <option value={"<"}>{"<"}</option>
                              <option value={"="}>{"="}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6} md={3}>
                          <Form.Group>
                            <Form.Label>Percentage 1A</Form.Label>
                            <Form.Control
                              type="text"
                              name="per1a"
                              value={this.state.per1a}
                              placeholder="Percentage 1A"
                              onChange={this.handleInputChange.bind(this)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </>

                  <>
                    <Row>
                      <Col xs={6} md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasic1Checkbox">
                          <Form.Check
                            key={this.state.keyC1b}
                            type="checkbox"
                            label="Critera 1B : Change in PE Ratio"
                            value={this.state.criteria1b}
                            name="criteria1b"
                            onChange={this.handleInputChange.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {this.state.criteria1b && (
                      <Row>
                        <Col xs={6} md={1}>
                          <Form.Group>
                            <Form.Label>Condition</Form.Label>
                            <Form.Control
                              as="select"
                              name="type1b"
                              value={this.state.type1b}
                              onChange={this.handleInputChange.bind(this)}>
                              <option value={">"}>{">"}</option>
                              <option value={"<"}>{"<"}</option>
                              <option value={"="}>{"="}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6} md={3}>
                          <Form.Group>
                            <Form.Label>Value 1B</Form.Label>
                            <Form.Control
                              type="text"
                              name="value1b"
                              value={this.state.value1b}
                              placeholder="Value 2B"
                              onChange={this.handleInputChange.bind(this)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </>

                  <Row>
                    <Col xs={1}>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={this.onClickFilter.bind(this)}>
                        Filter
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={this.onClickReset.bind(this)}>
                        Clear Filter
                      </Button>
                    </Col>
                  </Row>
                  <div style={{ height: 20 }} />
                  <label>
                    <b>Filtered Stocks : </b>
                    {this.state.filteredStocks.map((i) => i).join(", ") ||
                      "0 Stocks"}
                  </label>
                </Form>
                <div style={{ height: 10 }} />
              </div>
            </Card>
            <div style={{ height: 20 }} />

            <Card>
              <Card.Header style={{ fontWeight: "bold" }}>Stocks</Card.Header>
            </Card>
            <div style={{ height: 30 }} />

            {this.state.rows.length > 0 && this.state.cols.length > 0 && (
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  rows={this.state.rows}
                  columns={this.state.cols}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </div>
            )}
            <div style={{ height: 30 }} />
          </div>
        </div>
      </Container>
    );
  }
}

export default ViewDataScreen;
