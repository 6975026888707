import React from "react";
import Chart from "react-apexcharts";

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);
    let series = props.series;
    let categories = props.categories;
    let chartTitle = props.chartTitle;

    this.state = {
      series: series,
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: chartTitle,
          align: "center",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: categories,
          title: {
            text: "Stock Symbol",
          },
        },
        yaxis: {
          title: {
            text: "Value",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          floating: true,
          offsetY: -25,
          offsetX: 0,
        },
      },
    };
  }

  render() {
    return (
      // <ResizableBox>
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={350}
        />
      </div>
      // </ResizableBox>
    );
  }
}
