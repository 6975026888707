import React, { Component } from "react";

import { Container, Card } from "react-bootstrap";
import CustomNavBar from "../components/navbar";
import { callGetChartsData } from "../services/api";

class ViewChartsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await callGetChartsData();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getData();
      }
    );
  }

  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />

          <div>
            <Card>
              <Card.Header style={{ fontWeight: "bold" }}>Charts</Card.Header>
            </Card>
            <div style={{ height: 30 }} />

            <div style={{ height: 20 }} />
          </div>
        </div>
      </Container>
    );
  }
}

export default ViewChartsScreen;
