import moment from "moment";
import React, { Component } from "react";
import momentTz from "moment-timezone";

import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import CustomNavBar from "../components/navbar";
import { showNotification } from "../helpers";
import {
  callAddData,
  callUploadFile,
  callImportData,
  callGenerateFile,
} from "../services/api";

class AddDataScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockSymbol: "",
      price: "",
      volume: "",
      eps: "",
      div: "",
      xValue: "",
      isLoading: false,
      stockDate: new Date(),
      message: "",
      isUploading: false,
      file: "",
      response: {},
      importType: "",
      generateMessage: "",
      generateFile: "",
      isGenerateUploading: false,
      generateResponse: {},
      fileGenerated: false,
    };
  }

  componentDidMount() {}

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleFileChange(event) {
    let file = event.target.files[0];
    this.setState({
      isUploading: true,
      message: "Uploading.. Please wait",
      file: file,
    });
    let response = await callUploadFile(file);
    if (response.success) {
      response["importType"] = this.state.importType;
      this.setState({
        isUploading: false,
        response: response,
        message: "Upload done.. Click on import button to import data",
      });
    } else {
      this.setState({
        isUploading: false,
        file: "",
        message: "Error in uploading file.. Try again",
      });
      this.fileInput.value = "";
    }
  }

  async handleGenerateFileChange(event) {
    let file = event.target.files[0];
    this.setState({
      isGenerateUploading: true,
      generateMessage: "Uploading.. Please wait",
      generateFile: file,
      fileGenerated: false,
    });
    let response = await callUploadFile(file);
    if (response.success) {
      this.setState({
        isGenerateUploading: false,
        generateResponse: response,
        generateMessage: "Upload done.. Click on generate button",
      });
    } else {
      this.setState({
        isGenerateUploading: false,
        generateFile: "",
        generateMessage: "Error in uploading file.. Try again",
      });
      this.fileGenerateInput.value = "";
    }
  }

  async onClickImport() {
    this.setState({
      isUploading: true,
      file: "",
      message: "Importing data...Please wait",
    });
    let response = await callImportData(this.state.response);
    if (response.success) {
      this.setState({
        isUploading: false,
        message: `${response.count} records added, ${response.countUpdated} records updated`,
        file: "",
      });
      this.fileInput.value = "";
    } else {
      this.setState({
        isUploading: false,
        message: "Error in importing data. Try again",
        file: "",
      });
      this.fileInput.value = "";
    }
  }

  async onClickSubmit() {
    if (this.state.stockSymbol.toString().trim().length === 0) {
      showNotification("error", "Please enter Stock Symbol");
      return;
    }

    if (this.state.stockSymbol.trim().length === 0) {
      showNotification("error", "Stock symbol is required");
      return;
    }

    if (this.state.price.trim().length === 0) {
      showNotification("error", "Price is required");
      return;
    }

    if (this.state.volume.trim().length === 0) {
      showNotification("error", "Volume is required");
      return;
    }

    if (this.state.eps.trim().length === 0) {
      showNotification("error", "EPS is required");
      return;
    }

    if (this.state.div.trim().length === 0) {
      showNotification("error", "DIV is required");
      return;
    }

    if (!this.state.stockDate) {
      showNotification("error", "Stock date is required");
      return;
    }

    this.setState({ isLoading: true });
    let data = {
      stockSymbol: this.state.stockSymbol,
      stockDate: momentTz(this.state.stockDate)
        .tz("america/panama")
        .startOf("day")
        .toDate(),
      price: this.state.price,
      volume: this.state.volume,
      eps: this.state.eps,
      div: this.state.div,
      xValue: this.state.xValue,
    };

    let d = await callAddData(data);
    this.setState({ isLoading: false });
    if (d.success) {
      showNotification(
        "success",
        d.updated ? "Updated successfully" : "Added successfully"
      );
    }
  }

  async onClickGenerate() {
    this.setState({
      isGenerateUploading: true,
      generateFile: "",
      generateMessage: "Generating file.. Please wait",
    });
    let d = await callGenerateFile(this.state.generateResponse);
    if (d.success) {
      this.setState({
        isGenerateUploading: false,
        fileGenerated: true,
        generateMessage: "File generated. Download it from below link",
      });
      this.fileGenerateInput.value = "";
    } else {
      this.setState({
        isGenerateUploading: false,
        generateMessage: "Error in generating data. Try again",
        generateFile: "",
      });
      this.fileGenerateInput.value = "";
    }
  }

  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />
          <Card>
            <Card.Header style={{ fontWeight: "bold" }}>
              Add Stock Data
            </Card.Header>
          </Card>
          <div style={{ height: 30 }} />
          <Form>
            <Row>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Stock Symbol</Form.Label>
                  <Form.Control
                    type="text"
                    name="stockSymbol"
                    value={this.state.stockSymbol}
                    placeholder="Stock Symbol"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={this.state.price}
                    placeholder="Price"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Volume</Form.Label>
                  <Form.Control
                    type="text"
                    name="volume"
                    value={this.state.volume}
                    placeholder="Volume"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Eps</Form.Label>
                  <Form.Control
                    type="number"
                    name="eps"
                    value={this.state.eps}
                    placeholder="Eps"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Div</Form.Label>
                  <Form.Control
                    type="text"
                    name="div"
                    value={this.state.div}
                    placeholder="Div"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Stock Date</Form.Label>

                  <Form.Control
                    type="date"
                    name="stockDate"
                    value={moment(this.state.stockDate).format("YYYY-MM-DD")}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4}>
                <div style={{ height: 10 }} />
                <Button
                  variant="primary"
                  onClick={this.onClickSubmit.bind(this)}>
                  {this.state.isLoading ? "Loading..." : "Submit"}
                </Button>
              </Col>
            </Row>
            <div style={{ height: 30 }} />
          </Form>
          <div style={{ height: 30 }} />
          <Card>
            <Card.Header style={{ fontWeight: "bold" }}>
              Import Stock Data
            </Card.Header>
          </Card>
          <div style={{ height: 30 }} />
          <Form>
            <Row>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Import Type</Form.Label>
                  <Form.Check
                    type={"radio"}
                    label={"Date | Symbol | Price | EPS | Volume | Dividend"}
                    value={"type1"}
                    id="type1"
                    name={"importType"}
                    onChange={this.handleInputChange.bind(this)}
                  />
                  <Form.Check
                    type={"radio"}
                    label={"Date | Symbol | EPS | Volume | Dividend"}
                    value={"type2"}
                    id="type2"
                    name={"importType"}
                    onChange={this.handleInputChange.bind(this)}
                  />
                  <Form.Check
                    type={"radio"}
                    label={"Date | Symbol | Price"}
                    value={"type3"}
                    id="type3"
                    name={"importType"}
                    onChange={this.handleInputChange.bind(this)}
                  />
                  <div style={{ height: 30 }} />
                  {this.state.importType !== "" && (
                    <Form.Control
                      type="file"
                      name="importFile"
                      ref={(ref) => (this.fileInput = ref)}
                      onChange={this.handleFileChange.bind(this)}
                    />
                  )}
                </Form.Group>
                <p className="text-primary">{this.state.message}</p>
                {!this.state.isUploading && this.state.file && (
                  <Button
                    variant="primary"
                    onClick={this.onClickImport.bind(this)}>
                    Import
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <Card>
            <Card.Header style={{ fontWeight: "bold" }}>
              Generate Data
            </Card.Header>
          </Card>
          <div style={{ height: 30 }} />
          <p className="text-info">
            Import Type : Symbol | From Date | To Date | EPS | Dividend
          </p>
          <Form.Control
            type="file"
            name="importGenerateFile"
            ref={(ref) => (this.fileGenerateInput = ref)}
            onChange={this.handleGenerateFileChange.bind(this)}
          />
          <div style={{ height: 10 }} />
          <div style={{ height: 10 }} />

          <p className="text-success">{this.state.generateMessage}</p>
          {!this.state.isGenerateUploading &&
            this.state.generateFile &&
            !this.state.fileGenerated && (
              <Button
                variant="primary"
                onClick={this.onClickGenerate.bind(this)}>
                Generate File
              </Button>
            )}
          {this.state.fileGenerated && (
            <a href="http://localhost:5300/generatedFile.csv">Download File</a>
          )}
        </div>
      </Container>
    );
  }
}

export default AddDataScreen;
