import React, { Component } from "react";

import { Navbar, Nav, Button } from "react-bootstrap";
import { getItem, removeItem, setItem } from "../helpers/storage";
import { callVerifyAuthentication } from "../services/api";

class CustomNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkToken();
  }

  async checkToken() {
    let token = getItem("token");
    if (!token) {
      window.location.assign("/login");
    } else {
      // callVerifyAuthentication(token).then(
      //   (d) => {
      //     console.log(d);
      //     let currentTime = Math.floor(new Date().getTime() / 1000);
      //     if (!d.exp) {
      //       this.onClickLogout();
      //       return;
      //     }
      //     if (parseInt(d.exp, 0) < currentTime) {
      //       this.onClickLogout();
      //       return;
      //     }
      //     setItem("tokenExpiry", d.exp);
      //   },
      //   (error) => {
      //     this.onClickLogout();
      //   }
      // );
    }
  }

  onClickLogout() {
    removeItem("token");
    window.location.reload();
  }

  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">Stock App</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/">Add Stock</Nav.Link>
          <Nav.Link href="/symbol">Symbols</Nav.Link>
          <Nav.Link href="/filter1">Filter V1</Nav.Link>
          <Nav.Link href="/filter2">Filter V2</Nav.Link>
          <Nav.Link href="/filter3">Filter V3</Nav.Link>
          <Nav.Link href="/alpaca">Alpaca</Nav.Link>
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          <Button variant="outline" onClick={this.onClickLogout.bind(this)}>
            Logout
          </Button>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default CustomNavBar;
