import React, { Component } from "react";

import { Form, Button, Container, Row, Col } from "react-bootstrap";

import { callLoginAPI } from "../services/api";
import { showNotification, validateEmail } from "../helpers";
import { setItem } from "../helpers/storage";

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidMount() {}

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async onClickSubmit() {
    let email = this.state.email.trim();
    if (email.length === 0) {
      showNotification("error", "Email is required");
      return;
    }
    if (!validateEmail(email)) {
      showNotification("error", "Valid Email is required");
      return;
    }
    if (this.state.password.trim().length === 0) {
      showNotification("error", "Password is required");
      return;
    }
    let apiData = {
      email: email,
      password: this.state.password.trim(),
    };

    // login api call
    let d = await callLoginAPI(apiData);
    if (d.status) {
      // if success then redirect user to index page
      if (!d.active) {
        showNotification("error", "User is not active.");
        return;
      }
      showNotification("success", d.message);
      setItem("token", d.token);
      setItem("id", d.id);
      setItem("tokenExpiry", d.exp);

      this.props.history.push("/");
      window.location.assign("/");
      return;
    } else {
      // if there is error then prompt error to user
      showNotification("error", d.message);
      return;
    }
  }

  render() {
    // render method for login page
    return (
      <Container>
        <div style={{ height: 30 }} />
        <Form>
          <Row>
            <Col xs={6} md={4}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={this.state.email}
                  placeholder="Email Address"
                  onChange={this.handleInputChange.bind(this)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={this.state.password}
                  placeholder="Password"
                  onChange={this.handleInputChange.bind(this)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <Button variant="primary" onClick={this.onClickSubmit.bind(this)}>
                Login
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default LoginScreen;
