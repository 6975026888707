import moment from "moment";
import React, { Component } from "react";
import { DataGrid } from "@mui/x-data-grid";

import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Alert,
  Button,
} from "react-bootstrap";
import CustomNavBar from "../components/navbar";
import {
  callGetAlpacaData,
  callGetSymbolData,
  callImportAlpacaData,
} from "../services/api";
import { showNotification } from "../helpers";

class ViewAlpacaDataScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      rows: [],
      cols: [],
      startDate: moment().subtract(3, "day").format("YYYY-MM-DD"),
      endDate: moment().subtract(2, "day").format("YYYY-MM-DD"),
      errorMessage: "",
      isLoading: false,
      isImporting: false,
      symbol: "",
      symbols: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ isLoading: true });
    let d = await callGetSymbolData();
    if (d.data) {
      this.setState({
        symbols: d.data,
      });
      if (d.data.length > 0) {
        this.getAlpacaData();
      }
    }
  }

  async getAlpacaData() {
    let symbol = this.state.symbol;
    if (!symbol) {
      symbol = this.state.symbols[0].stockSymbol;
    }
    let d = await callGetAlpacaData(
      this.state.startDate,
      this.state.endDate,
      symbol
    );
    this.setState({
      isLoading: false,
      rows: d.rows,
      cols: d.cols,
      symbol: symbol,
      errorMessage: d.errorMessages.length > 0 ? d.errorMessages[0] : "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
        isLoading: true,
      },
      () => {
        this.getAlpacaData();
      }
    );
  }

  async onClickImport() {
    if (this.state.isImporting) {
      return;
    }

    this.setState({ isImporting: true });

    let data = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      symbol: this.state.symbol,
    };
    let d = await callImportAlpacaData(data);
    this.setState({ isImporting: false });
    if (d.success) {
      showNotification("success", "Import data successfully");
    }
  }

  renderSymbols() {
    let returnVals = [];

    this.state.symbols.forEach((element) => {
      returnVals.push(
        <option value={element.stockSymbol}>{element.stockSymbol}</option>
      );
    });

    return returnVals;
  }

  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />

          <div>
            <Card>
              <Card.Header style={{ fontWeight: "bold" }}>
                Alpaca Data
              </Card.Header>
            </Card>
            <div style={{ height: 30 }} />
            <Card>
              <div className="container-fluid">
                <div style={{ height: 10 }} />
                <Form>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          placeholder="Start Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={this.state.endDate}
                          placeholder="End Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={2} md={2}>
                      <Form.Group>
                        <Form.Label>Stock Symbol</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          name="symbol"
                          onChange={this.handleInputChange.bind(this)}>
                          {this.renderSymbols()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div style={{ height: 10 }} />
              </div>
            </Card>
            <div style={{ height: 20 }} />
            {!this.state.isLoading ? (
              <div>
                {this.state.rows.length > 0 && this.state.cols.length > 0 ? (
                  <div>
                    <Alert variant={"primary"}>
                      Click on <b>Import</b> button to import data into our
                      database
                    </Alert>
                    {!this.state.isImporting ? (
                      <Button
                        variant="primary"
                        onClick={this.onClickImport.bind(this)}>
                        Import
                      </Button>
                    ) : (
                      <div>
                        <div style={{ height: 10 }} />
                        <label>Importing Data... Please wait</label>
                      </div>
                    )}
                    <div style={{ height: 10 }} />
                    <div style={{ height: 650, width: "100%" }}>
                      <DataGrid
                        rows={this.state.rows}
                        columns={this.state.cols}
                        pageSize={30}
                        rowsPerPageOptions={[30]}
                      />
                    </div>
                  </div>
                ) : (
                  <Alert variant={"info"}>
                    No data found for {this.state.symbol}
                  </Alert>
                )}
                {this.state.errorMessage.length > 0 && (
                  <div>
                    <label>Error from Alpaca API</label>
                    <div style={{ height: 10 }} />
                    <div>
                      <Alert variant={"danger"}>
                        {this.state.errorMessage}
                      </Alert>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <label>Getting data.. Please wait</label>
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default ViewAlpacaDataScreen;
