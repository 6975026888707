import { toast } from "react-toastify";

export function validateEmail(email) {
  // validate email function
  /*eslint-disable*/
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /*eslint-enable*/
  return re.test(String(email).toLowerCase());
}

export function checkPassword(s) {
  // check password function
  var re = /[a-z]\d|\d[a-z]/i;
  return re.test(s) && s.length > 6;
}

export const showNotification = (type, message, duration = 4000) => {
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: duration,
      });
      break;
    case "success":
      toast.success(message, {
        autoClose: duration,
      });
      break;
    case "warning":
      toast.warn(message, {
        autoClose: duration,
      });
      break;
    case "error":
      toast.error(message, {
        autoClose: duration,
      });
      break;
    default:
      toast(message, {
        autoClose: duration,
      });
      break;
  }
};
