import moment from "moment";
import React, { Component } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Container, Card, Form, Row, Col } from "react-bootstrap";
import CustomNavBar from "../components/navbar";
import { callGetDataV3 } from "../services/api";

class ViewDataV3Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      rows: [],
      cols: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    let d = await callGetDataV3(this.state.startDate, this.state.endDate);
    this.setState({ rows: d.rows, cols: d.cols });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getData();
      }
    );
  }

  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />

          <div>
            <Card>
              <Card.Header style={{ fontWeight: "bold" }}>
                Filter V3
              </Card.Header>
            </Card>
            <div style={{ height: 30 }} />
            <Card>
              <div className="container-fluid">
                <div style={{ height: 10 }} />
                <Form>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          placeholder="Start Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={this.state.endDate}
                          placeholder="End Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div style={{ height: 10 }} />
              </div>
            </Card>
            <div style={{ height: 20 }} />
            {this.state.rows.length > 0 && this.state.cols.length > 0 && (
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  rows={this.state.rows}
                  columns={this.state.cols}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default ViewDataV3Screen;
