import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import LoginScreen from "./views/login";
import AddDataScreen from "./views/addData";
import ViewDataScreen from "./views/viewData";
import ViewDataV2Screen from "./views/viewDataV2";
import ViewDataV3Screen from "./views/viewDataV3";
import ViewAlpacaDataScreen from "./views/viewAlpacaData";
import ViewChartsScreen from "./views/viewCharts";
import SymbolScreen from "./views/symbolData";

toast.configure({ position: toast.POSITION.TOP_RIGHT });

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route path="/login" exact component={LoginScreen} />
          <Route path="/" exact component={AddDataScreen} />
          <Route path="/symbol" exact component={SymbolScreen} />
          <Route path="/filter1" exact component={ViewDataScreen} />
          <Route path="/filter2" exact component={ViewDataV2Screen} />
          <Route path="/filter3" exact component={ViewDataV3Screen} />
          <Route path="/alpaca" exact component={ViewAlpacaDataScreen} />
          <Route path="/charts" exact component={ViewChartsScreen} />
        </div>
      </Router>
    );
  }
}

export default App;
