import { getItem } from "../helpers/storage";

const API_URL = process.env.REACT_APP_API_URL;

function processRequest(request) {
  return fetch(request)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      return err;
    });
}

export function callLoginAPI(data) {
  let url = `${API_URL}/users/login`;

  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json" },
  });
  return processRequest(request);
}

export function callVerifyAuthentication(token) {
  let url = `${API_URL}/users/login/verify`;

  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify({ token: token }),
    headers: { "content-type": "application/json" },
  });
  return processRequest(request);
}

function getHeaders() {
  let token = getItem("token");
  return Object.assign({
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
}

export function callAddData(data) {
  let url = `${API_URL}/data`;

  let headers = getHeaders();
  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return processRequest(request);
}

export function callImportAlpacaData(data) {
  let url = `${API_URL}/data/alpaca`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return processRequest(request);
}

export function callAddSymbolData(data) {
  let url = `${API_URL}/data/symbol`;
  let headers = getHeaders();
  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return processRequest(request);
}

export function callGetData() {
  let url = `${API_URL}/data`;
  let headers = getHeaders();
  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

export function callGetDataV2(startDate, endDate) {
  let url = `${API_URL}/data/v2?startDate=${startDate}&endDate=${endDate}`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

export function callGetChartsData() {
  let url = `${API_URL}/data/charts`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

export function callGetDataV3(startDate, endDate) {
  let url = `${API_URL}/data/v3?startDate=${startDate}&endDate=${endDate}`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

export function callGetAlpacaData(startDate, endDate, symbol) {
  let url = `${API_URL}/data/alpaca?startDate=${startDate}&endDate=${endDate}&symbol=${symbol}`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

export function callGetSymbolData() {
  let url = `${API_URL}/data/symbol`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

export function callUploadFile(file) {
  let data = new FormData();
  data.append("file", file);

  let url = `${API_URL}/data/upload`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    body: data,
    headers: headers,
  });
  return processRequest(request);
}

export function callImportData(data) {
  let url = `${API_URL}/data/import`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return processRequest(request);
}

export function callImportSymbolData(data) {
  let url = `${API_URL}/data/symbol-import`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return processRequest(request);
}

export function callGenerateFile(data) {
  let url = `${API_URL}/data/generate-file`;
  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return processRequest(request);
}
