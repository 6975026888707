// import securels related functions from secure-ls pacakage installed.
import SecureLS from "secure-ls";

// set localstorage - in browser
var storage = new SecureLS({ encodingType: "aes", isCompression: true });

function setItem(key, value) {
  // set new value to key
  storage.set(key, value);
}

function getItem(key) {
  // get key value
  try {
    return storage.get(key);
  } catch (error) {
    removeAll();
  }
}

function removeItem(key) {
  // remove key from storage
  return storage.remove(key);
}

function removeAll() {
  // remove all storage variables
  return storage.removeAll();
}

export { setItem, getItem, removeItem, removeAll };
