function downloadCsv(csvData, csvFileName) {
  const encodedUri = encodeURI(csvData);
  const formattedFileName = csvFileName || new Date().toISOString();
  const link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${formattedFileName}.csv`);

  document.body.appendChild(link);

  link.click();
}

function formatData(csvHeaders, csvRows) {
  let csvData = "data:text/csv;charset=utf-8,";
  let csvDataArray = [];
  const keyList = [];

  // Insert the header ids into the key list
  csvHeaders.forEach((header) => {
    keyList.push(header.id);
  });

  // Insert the row keys into the key list
  csvRows.forEach((row) => {
    const keys = Object.keys(row);

    keys.forEach((key) => {
      if (keyList.indexOf(key) === -1) {
        keyList.push(key);
      }
    });
  });

  // Insert header ids into the csv
  if (csvHeaders.length > 0) {
    csvDataArray.push(
      csvHeaders.reduce((result, header) => {
        result.push(header.display || header.id || " ");

        return result;
      }, [])
    );
  }

  // Insert row data into the csv
  csvDataArray = csvDataArray.concat(
    csvRows.map((row) => {
      return keyList.map((key) => {
        return `"${row[key]}"` || "";
      });
    })
  );

  csvDataArray.forEach((infoArray, index) => {
    const dataString = infoArray.join(",");

    csvData += index < csvRows.length ? `${dataString}\n` : dataString;
  });

  return csvData;
}

export function exportCSV(headers, rows, filename) {
  downloadCsv(formatData(headers, rows), filename);
}
