import React, { Component } from "react";

import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
} from "react-bootstrap";
import { exportCSV } from "../components/exportCSV";
import CustomNavBar from "../components/navbar";
import { showNotification } from "../helpers";
import {
  callAddSymbolData,
  callGetSymbolData,
  callImportSymbolData,
  callUploadFile,
} from "../services/api";

class SymbolDataScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockSymbol: "",
      name: "",
      sector: "",
      index: "",
      fcstDiv: "",
      fcstEps: "",
      notes: "",
      subSector: "",
      symbols: [],
      isLoading: false,
      stockDate: new Date(),
      isUploading: false,
      file: "",
      message: "",
      importType: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    let d = await callGetSymbolData();
    this.setState({ symbols: d.data });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async onClickSubmit() {
    if (this.state.stockSymbol.toString().trim().length === 0) {
      showNotification("error", "Please enter Stock Symbol");
      return;
    }
    if (this.state.name.toString().trim().length === 0) {
      showNotification("error", "Please enter Name");
      return;
    }
    if (this.state.sector.toString().trim().length === 0) {
      showNotification("error", "Please enter Sector");
      return;
    }
    if (this.state.subSector.toString().trim().length === 0) {
      showNotification("error", "Please enter Sub Sector");
      return;
    }
    if (this.state.index.toString().trim().length === 0) {
      showNotification("error", "Please enter Index");
      return;
    }
    if (this.state.fcstDiv.toString().trim().length === 0) {
      showNotification("error", "Please enter fcstDiv");
      return;
    }
    if (this.state.fcstEps.toString().trim().length === 0) {
      showNotification("error", "Please enter fcstEps");
      return;
    }

    this.setState({ isLoading: true });
    let data = {
      stockSymbol: this.state.stockSymbol,
      name: this.state.name,
      sector: this.state.sector,
      index: this.state.index,
      fcstDiv: this.state.fcstDiv,
      fcstEps: this.state.fcstEps,
      notes: this.state.notes,
      subSector: this.state.subSector,
    };
    let d = await callAddSymbolData(data);
    this.setState({ isLoading: false });
    if (d.success) {
      this.getData();
      showNotification("success", "Added successfully");
    }
  }

  onClickExport() {
    let headers = [
      { id: "stockSymbol", display: "Stock Symbol" },
      { id: "name", display: "Name" },
      { id: "sector", display: "Sector" },
      { id: "subSector", display: "Sub Sector" },
      { id: "index", display: "Index" },
      { id: "fcstDiv", display: "fcstDiv" },
      { id: "fcstEps", display: "fcstEps" },
      { id: "notes", display: "Notes" },
    ];

    let rows = [];

    this.state.symbols.forEach((element) => {
      let ele = {};
      headers.forEach((header) => {
        ele[header.id] = element[header.id] || "";
      });
      rows.push(ele);
    });

    exportCSV(headers, rows, "symbols");
  }

  async handleFileChange(event) {
    let file = event.target.files[0];
    this.setState({
      isUploading: true,
      message: "Uploading.. Please wait",
      file: file,
    });
    let response = await callUploadFile(file);
    if (response.success) {
      response["importType"] = this.state.importType;
      this.setState({
        isUploading: false,
        response: response,
        message: "Upload done.. Click on import button to import data",
      });
    } else {
      this.setState({
        isUploading: false,
        file: "",
        message: "Error in uploading file.. Try again",
      });
      this.fileInput.value = "";
    }
  }

  async onClickImport() {
    this.setState({
      isUploading: true,
      file: "",
      message: "Importing data...Please wait",
    });
    let response = await callImportSymbolData(this.state.response);
    if (response.success) {
      this.setState({
        isUploading: false,
        message: `${response.count} records added, ${response.countUpdated} records updated`,
        file: "",
      });
      this.fileInput.value = "";
    } else {
      this.setState({
        isUploading: false,
        message: "Error in importing data. Try again",
        file: "",
      });
      this.fileInput.value = "";
    }
  }

  renderData() {
    let returnVals = [];
    this.state.symbols.forEach((element) => {
      if (element.stockSymbol) {
        returnVals.push(
          <tr key={element._id}>
            <td>{element.stockSymbol || ""}</td>
            <td>{element.name || ""}</td>
            <td>{element.sector || ""}</td>
            <td>{element.subSector || ""}</td>
            <td>{element.index || ""}</td>
            <td>{element.fcstDiv || ""}</td>
            <td>{element.fcstEps || ""}</td>
            <td>{element.notes || ""}</td>
          </tr>
        );
      }
    });
    return returnVals;
  }

  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />
          <Card>
            <Card.Header style={{ fontWeight: "bold" }}>
              Add Symbol Data
            </Card.Header>
          </Card>
          <div style={{ height: 30 }} />
          <Form>
            <Row>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Stock Symbol</Form.Label>
                  <Form.Control
                    type="text"
                    name="stockSymbol"
                    value={this.state.stockSymbol}
                    placeholder="Stock Symbol"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={this.state.name}
                    placeholder="Name"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Sector</Form.Label>
                  <Form.Control
                    type="text"
                    name="sector"
                    value={this.state.sector}
                    placeholder="Sector"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Sub Sector</Form.Label>
                  <Form.Control
                    type="text"
                    name="subSector"
                    value={this.state.subSector}
                    placeholder="Sub Sector"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Index</Form.Label>
                  <Form.Control
                    type="text"
                    name="index"
                    value={this.state.index}
                    placeholder="Index"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>fcstDiv</Form.Label>
                  <Form.Control
                    type="number"
                    name="fcstDiv"
                    value={this.state.fcstDiv}
                    placeholder="fcstDiv"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>fcstEps</Form.Label>
                  <Form.Control
                    type="number"
                    name="fcstEps"
                    value={this.state.fcstEps}
                    placeholder="fcstEps"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    type="text"
                    name="notes"
                    value={this.state.notes}
                    placeholder="Notes"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={6} md={4}>
                <div style={{ height: 10 }} />
                <Button
                  variant="primary"
                  onClick={this.onClickSubmit.bind(this)}>
                  {this.state.isLoading ? "Loading..." : "Submit"}
                </Button>
              </Col>
            </Row>
            <div style={{ height: 30 }} />
          </Form>
          <div style={{ height: 30 }} />

          <Card>
            <Card.Header style={{ fontWeight: "bold" }}>
              Import Symbol Data
            </Card.Header>
          </Card>
          <div style={{ height: 30 }} />
          <Form>
            <Row>
              <Col xs={6} md={4}>
                <Form.Group>
                  <Form.Label>Import Type</Form.Label>
                  <Form.Check
                    type={"radio"}
                    label={
                      "Symbol | Name | Sector | Sub Sector | Index | fcstDiv | fcstEps | Notes"
                    }
                    value={"type1"}
                    id="type1"
                    name={"importType"}
                    onChange={this.handleInputChange.bind(this)}
                  />
                  <Form.Check
                    type={"radio"}
                    label={
                      "Symbol | Name | Sector | Sub Sector | Index | fcstDiv | fcstEps"
                    }
                    value={"type2"}
                    id="type2"
                    name={"importType"}
                    onChange={this.handleInputChange.bind(this)}
                  />
                  <div style={{ height: 30 }} />
                  {this.state.importType !== "" && (
                    <Form.Control
                      type="file"
                      name="importFile"
                      ref={(ref) => (this.fileInput = ref)}
                      onChange={this.handleFileChange.bind(this)}
                    />
                  )}
                </Form.Group>
                <p className="text-primary">{this.state.message}</p>
                {!this.state.isUploading && this.state.file && (
                  <Button
                    variant="primary"
                    onClick={this.onClickImport.bind(this)}>
                    Import
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <div style={{ height: 30 }} />
          <Card>
            <Card.Header style={{ fontWeight: "bold" }}>
              View Symbol Data
            </Card.Header>
          </Card>
          <div style={{ height: 30 }} />
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>Stock</th>
                <th>Name</th>
                <th>Sector</th>
                <th>Sub Sector</th>
                <th>Index</th>
                <th>fcstDiv</th>
                <th>fcstEps</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>{this.renderData()}</tbody>
          </Table>
          {/* <Button
            variant="secondary"
            className="btn-sm"
            onClick={this.onClickExport.bind(this)}>
            Export Data
          </Button> */}
        </div>
      </Container>
    );
  }
}

export default SymbolDataScreen;
