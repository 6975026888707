import moment from "moment";
import React, { Component } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Container, Card, Form, Row, Col, Button } from "react-bootstrap";
import CustomNavBar from "../components/navbar";
import { callGetDataV2 } from "../services/api";
import LineChart from "../components/lineChart";

class ViewDataV2Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      rows: [],
      cols: [],
      startDate: moment("2021-11-01").format("YYYY-MM-DD"),
      endDate: moment("2021-11-29").format("YYYY-MM-DD"),
      chartData: [],
      keyChart: Math.random(),
      baskets: 20,
      showChart: false,
      arrStocks: [],
      sortBy: "xAutoPer",
      sectorChartData: {},
      keySectorChart: Math.random(),
    };
  }

  componentDidMount() {
    this.getData();
  }

  generateChartData() {
    let chartData = [];
    this.setState({ chartData: [], keyChart: Math.random() });
    let arr1Stocks = this.state.arrStocks;
    let sortBy = this.state.sortBy;

    arr1Stocks = arr1Stocks.sort((a, b) => a[sortBy] - b[sortBy]);

    var i,
      j,
      k = 0,
      chunk = arr1Stocks.length / this.state.baskets;

    for (i = 0, j = arr1Stocks.length; i < j; i += chunk) {
      let arrChunk = arr1Stocks.slice(i, i + chunk);
      let objxData = {
        name: "xAuto%",
        data: [],
      };
      let objyData = {
        name: "yAuto%",
        data: [],
      };
      let categories = [];

      arrChunk.forEach((element) => {
        objxData.data.push(parseFloat(element.xAutoPer));
        objyData.data.push(parseFloat(element.yAutoPer));
        categories.push(element.stockSymbol);
      });
      let series = [objxData, objyData];

      chartData.push({
        series: series,
        categories: categories,
        basketNumber: ++k,
      });
    }

    this.setState({
      chartData: chartData,
      keyChart: Math.random(),
      showChart: true,
    });
  }

  generateSectorChartData() {
    let arrStocks = this.state.arrStocks;
    let sectorMap = {};
    arrStocks.forEach((element) => {
      if (!sectorMap[element.sector]) {
        if (element.sector) {
          sectorMap[element.sector] = [];
        }
      }
      if (element.sector) {
        sectorMap[element.sector].push(element);
      }
    });
    let newSectorMap = {};
    for (const key in sectorMap) {
      if (Object.hasOwnProperty.call(sectorMap, key)) {
        const arrChunk = sectorMap[key];
        let objxData = {
          name: "xAuto%",
          data: [],
        };
        let objyData = {
          name: "yAuto%",
          data: [],
        };
        let categories = [];

        arrChunk.forEach((element) => {
          objxData.data.push(parseFloat(element.xAutoPer));
          objyData.data.push(parseFloat(element.yAutoPer));
          categories.push(element.stockSymbol);
        });
        let series = [objxData, objyData];

        newSectorMap[key] = {
          series: series,
          categories: categories,
          chartTitle: key,
        };
      }
    }
    this.setState({
      sectorChartData: newSectorMap,
      keySectorChart: Math.random(),
    });
  }

  async getData() {
    let d = await callGetDataV2(this.state.startDate, this.state.endDate);

    this.setState(
      {
        rows: d.rows,
        cols: d.cols,
        arrStocks: d.arrStocks,
      },
      () => {
        this.generateChartData();
        this.generateSectorChartData();
      }
    );
  }

  renderSectorChart() {
    var returnVals = [];
    let sectorMap = this.state.sectorChartData;
    for (const key in sectorMap) {
      if (Object.hasOwnProperty.call(sectorMap, key)) {
        const element = sectorMap[key];
        returnVals.push(
          <div className="col-sm-4" style={{ paddingBottom: 30 }}>
            <Card>
              <LineChart
                series={element.series}
                categories={element.categories}
                chartTitle={element.chartTitle}
              />
            </Card>
          </div>
        );
      }
    }
    return returnVals;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getData();
      }
    );
  }

  handleBasketsChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickSubmit() {
    this.generateChartData();
  }
  render() {
    return (
      <Container as="xl">
        <div className="container-fluid">
          <CustomNavBar />
          <div style={{ height: 30 }} />

          <div>
            <Card>
              <Card.Header style={{ fontWeight: "bold" }}>
                Filter V2
              </Card.Header>
            </Card>
            <div style={{ height: 30 }} />
            <Card>
              <div className="container-fluid">
                <div style={{ height: 10 }} />
                <Form>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          placeholder="Start Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={this.state.endDate}
                          placeholder="End Date"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div style={{ height: 10 }} />
              </div>
            </Card>
            <div style={{ height: 20 }} />
            {this.state.rows.length > 0 && this.state.cols.length > 0 && (
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  rows={this.state.rows}
                  columns={this.state.cols}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </div>
            )}
            <div style={{ height: 30 }} />

            {this.state.showChart ? (
              <>
                <Card>
                  <Card.Header style={{ fontWeight: "bold" }}>
                    Charts #1
                  </Card.Header>
                </Card>
                <div style={{ height: 30 }} />
                <Row>
                  <Col xs={2} md={2}>
                    <Form.Group>
                      <Form.Label>Baskets</Form.Label>
                      <Form.Control
                        type="number"
                        name="baskets"
                        value={this.state.baskets}
                        placeholder="Baskets"
                        onChange={this.handleBasketsChange.bind(this)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={2}>
                    <Form.Group>
                      <Form.Label>Sort By</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="sortBy"
                        onChange={this.handleBasketsChange.bind(this)}>
                        <option value={"xAutoPer"}>xAuto%</option>
                        <option value={"yAutoPer"}>yAuto%</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={2}>
                    <div style={{ height: 33 }} />
                    <Button
                      variant="primary"
                      onClick={this.onClickSubmit.bind(this)}>
                      Generate Charts
                    </Button>
                  </Col>
                </Row>
                <div style={{ height: 10 }} />
                <div className="row" key={this.state.keyChart}>
                  {this.state.chartData.map((i) => (
                    <div className="col-sm-4" style={{ paddingBottom: 30 }}>
                      <Card>
                        <LineChart
                          series={i.series}
                          categories={i.categories}
                          chartTitle={`Basket ${i.basketNumber}`}
                        />
                      </Card>
                    </div>
                  ))}
                </div>

                <div style={{ height: 30 }} />
                <Card>
                  <Card.Header style={{ fontWeight: "bold" }}>
                    Charts #2 - Sector
                  </Card.Header>
                </Card>
                <div style={{ height: 30 }} />
                <div className="row" key={this.state.keySectorChart}>
                  {this.renderSectorChart()}
                </div>
              </>
            ) : (
              <div>
                <label>Generating Charts.. Please wait</label>
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default ViewDataV2Screen;
